import React from "react";
import { NavLink } from "react-router-dom";
import bikerImg from "../../images/biker.jpg";
import DateFormatter from "../../common/DateFromatter.js";

const ProfileHeader = ({ userData, userId, currentUser }) => {
  const {
    picture,
    first_name,
    last_name,
    type,
    phone,
    email,
    branch,
    company,
    birthday,
    address,
    gender,
  } = userData;

  return (
    <header className="profile-header">
      <div className="profile-picture">
        <img
          src={
            picture
              ? `${process.env.REACT_APP_SERVER_API}/upload/profiles/${picture}`
              : bikerImg
          }
          alt="Profile"
        />
      </div>
      <div className="profile-info">
        <h1>
          {first_name} {last_name}
        </h1>
        <p>{type}</p>
        <p>{phone}</p>
        <p>{email}</p>
        <p>{branch ? branch.label : ""}</p>
        <p>{company}</p>
        <p>Address: {address || "N/A"}</p>
        <p>Gender: {gender || "N/A"}</p>
        <p>Birthday: {DateFormatter.DateFormatterTwo(birthday)}</p>
      </div>
      {(currentUser.type === "admin" ||
        (currentUser.type === "manager" && type != "admin")) && (
        <NavLink to={`/user/edit/${userId}`} className="edit-button">
          Edit
        </NavLink>
      )}
    </header>
  );
};

export default ProfileHeader;
