import React, { useState, useEffect } from "react";

import PackageService from "../services/package.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";

import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import { getBranches } from "../common/getFunctions";
import BranchePullDown from "../commonComponents/BranchePullDown";
import { Card, Row, Col } from "react-bootstrap";
import DateFromatter from "../common/DateFromatter";
import { viewPDF } from "../common/pdf";
import Modal from "react-bootstrap/Modal";
import PullDown from "../commonComponents/PullDown";
import { getCategories } from "../common/getFunctions";
import RequiredLabel from "../common/RequiredLabel";
import OptionalLabel from "../common/OptionalLabel";
import TerminationModal from "../commonComponents/TerminationModal";
import InternalNoteModal from "../commonComponents/InternalNoteModal";
import InternalNoteComponent from "../commonComponents/InternalNoteComponent";

const currentUser = JSON.parse(localStorage.getItem("user"));
const currentDate = DateFromatter.getCurrentDate();

const Packages = () => {
  const [bikeNumber, setBikeNumber] = useState("");
  const [error, setError] = useState("");
  const [packages, setPackages] = useState([]);
  const [branchFilter, setBranchFilter] = useState();
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [terminateSubscriptionModal, setTerminateSubscriptionModal] =
    useState(false);
  const [terminationCategory, setTerminationCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [reason, setReason] = useState("");
  const [packageName, setPackageName] = useState("");
  const [packageIdToTerminate, setPackageNameIdToTerminate] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [internalNoteModal, setInternalNoteModal] = useState(false);
  const [selectedUserByNote, setSelectedUserByNote] = useState("");
  const [oldInternalNote, setOldInternalNote] = useState("");
  const pageSizes = [9, 18, 24, 30, 36, 60];

  useEffect(() => {
    async function load() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      setCategories(await getCategories(currentUser.branch, "termination"));
      setBranchFilter(currentUser.branch);
      await listPackages(currentUser.branch, "", "", page, itemsPerPage);
    }
    load();
  }, []);

  const listPackages = async (
    branchFilter,
    bikeNumber,
    packageName,
    page,
    itemsPerPage
  ) => {
    setLoading(true);
    PackageService.getPackagesList(
      branchFilter,
      bikeNumber,
      packageName,
      parseInt(page - 1) * itemsPerPage,
      itemsPerPage,
      false
    ).then(
      (response) => {
        let data = response.data.data;
        setPackages(data.list);
        //console.log(data.list)
        setTotal(data ? data.total : 0);
        setCount(Math.ceil(data.total / itemsPerPage));
        setResetMsg("");
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setError(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };
  const handlePageChange = async (event, value) => {
    setPage(value);
    await listPackages(
      branchFilter,
      bikeNumber,
      packageName,
      value,
      itemsPerPage
    );
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await listPackages(
      branchFilter,
      bikeNumber,
      packageName,
      1,
      newItemsPerPage
    );
  };
  const resetSearch = async () => {
    setBranchFilter("");
    await listPackages("", "", "", page, itemsPerPage);
  };
  const triggerSearch = async () => {
    await setPage(1); // return to first page
    await listPackages(branchFilter, bikeNumber, packageName, 1, itemsPerPage);
  };
  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    setCategories(await getCategories(bid, "termination"));
    await resetResults();
  };
  const resetResults = async () => {
    setTotal(0);
    setCount(0);
    setBranchFilter(currentUser.branch);
    setCategories(await getCategories(currentUser.branch, "termination"));
    setPackages([]);
    setResetMsg(labels.RESET_MSG);
  };

  const handleBikeChange = async (id) => {
    setBikeNumber(id);
    await resetResults();
  };
  const handlePackageChange = async (val) => {
    setPackageName(val);
    await resetResults();
  };
  const deletePackage = async (event, id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this package? \n Make sure no events are created for this package!"
      )
    ) {
      PackageService.deletePackage(id).then(
        (response) => {
          alert("Package deleted!");
          let oldList = packages;
          var data = oldList.filter(function (obj) {
            return obj._id !== id;
          });
          setPackages(data);
          setTotal(total - 1);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  const handleAddInternalNoteClick = (id, internalNote) => {
    setSelectedUserByNote(id);
    setOldInternalNote(internalNote);
    setInternalNoteModal(true);
  };

  const handleReasonModalShow = (id) => {
    //var reasonByUser = waitings.find((user) => user._id === id);
    //setComment(commentByUser.waitingNote)
    setPackageNameIdToTerminate(id);

    setTerminateSubscriptionModal(true);
  };
  const handleTerminateSubscriptionModalClose = () => {
    setPackageNameIdToTerminate("");
    setReason("");
    setTerminationCategory("");
    setTerminateSubscriptionModal(false);
  };
  const sendReasonHandler = () => {
    if (!terminationCategory && !reason) {
      alert("Terminate reason is required!");
      return;
    }

    var data = {
      id: packageIdToTerminate,
      terminationCategory: terminationCategory,
      terminateReason: reason,
    };

    PackageService.terminateSubscription(data).then(async ({ data }) => {
      setPackages(
        packages.map((s, i) => {
          if (s._id === packageIdToTerminate) {
            s.terminated = true;
            s.terminateDate = currentDate;
            s.lastDate =
              data && data.data && data.data.lastDate ? data.data.lastDate : "";
            s.nextRenewal =
              data && data.data && data.data.nextRenewal
                ? data.data.nextRenewal
                : "";
          }
          return s;
        })
      );
    });

    handleTerminateSubscriptionModalClose();
  };
  const activateSubscription = (id) => {
    if (
      window.confirm(
        "Are you sure you want to activate this subscription again?"
      )
    ) {
      PackageService.activateSubscription(id).then(
        (res) => {
          alert("Package Activated!");
          setPackages(
            packages.map((s, i) => {
              if (s._id === id) {
                s.terminated = false;
                s.lastDate = "";
                s.nextRenewal =
                  res.data && res.data.data && res.data.data.nextRenewal
                    ? res.data.data.nextRenewal
                    : "";
              }
              return s;
            })
          );
        },
        (error) => {
          console.log(error);
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };
  const markPackageAsReturned = (id) => {
    if (
      window.confirm(
        "This action means that everything on the package has been returned: bikes, batteriers, chanrgers, bages,.. \n\n Warning: Role back is not possible! \n\n Confirm this action?"
      )
    ) {
      PackageService.markPackageAsReturned(id).then(
        (res) => {
          alert("Package has been marked as returned.");
          setPackages(
            packages.map((s, i) => {
              if (s._id === id) {
                s.packageReturned = true;
                s.packageReturnedDate = currentDate;
              }
              return s;
            })
          );
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  return (
    <>
      <TerminationModal
        terminateSubscriptionModal={terminateSubscriptionModal}
        handleTerminateSubscriptionModalClose={
          handleTerminateSubscriptionModalClose
        }
        terminationCategory={terminationCategory}
        setTerminationCategory={setTerminationCategory}
        categories={categories}
        reason={reason}
        setReason={setReason}
        sendReasonHandler={sendReasonHandler}
      />
      <InternalNoteModal
        internalNoteModal={internalNoteModal}
        setInternalNoteModal={setInternalNoteModal}
        selectedUserByNote={selectedUserByNote}
        oldInternalNote={oldInternalNote}
        list={packages}
        setList={setPackages}
        isSubscription
        isPackage
      />

      <div className="container">
        {currentUser.type == "admin" || currentUser.type == "manager" ? (
          <div className="floatRight ">
            <NavLink to={"/package/new"} className="greenLink mx-0">
              Add Package
            </NavLink>
          </div>
        ) : (
          ""
        )}
        <h3> Packages List </h3>
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="filterContainer"
        >
          <Row style={{ display: "flex", flexDirection: "row" }}>
            {currentUser && currentUser.type == "admin" ? (
              <Col md={2}>
                <BranchePullDown
                  branch={branchFilter}
                  labelInside={false}
                  forceBlank={true}
                  setBranch={handleBranchChange}
                  branches={branches}
                  addFormGroup="no"
                  extraClassLabel="filterLabelInput maintenanceLabels"
                />
              </Col>
            ) : null}
            <Col md={2}>
              <label
                className="filterLabelInput maintenanceLabels"
                htmlFor="packageName"
              >
                Package Name
              </label>
              <input
                type="text"
                className="form-control filterInput2"
                name="packageName"
                value={packageName}
                onChange={(e) => handlePackageChange(e.target.value)}
              />
            </Col>
            <Col md={2}>
              <label
                className="filterLabelInput maintenanceLabels"
                htmlFor="bikeNumber"
              >
                Bike Number
              </label>
              <input
                type="text"
                className="form-control filterInput2"
                name="bikeNumber"
                value={bikeNumber}
                onChange={(e) => handleBikeChange(e.target.value)}
              />
            </Col>
            <Col md={2}>
              <label className="filterLabelInput maintenanceLabels">
                &nbsp;
              </label>
              <table border="0">
                <tr>
                  <td>
                    <button
                      onClick={(e) => triggerSearch(e)}
                      className="btn btn-primary btn-block search-button "
                    >
                      Go
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={(e) => resetSearch(e)}
                      className="btn btn-primary btn-block reset-button "
                    >
                      Reset
                    </button>
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
        {error ? (
          <header className="jumbotron">
            {" "}
            <h3>{error}</h3>{" "}
          </header>
        ) : null}
        {!error && !loading && packages && packages.length != 0 ? (
          <div>
            <Row>
              <Col>
                <span style={{ float: "right", marginLeft: 10 }}>
                  {total} results
                </span>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "first baseline",
              }}
            >
              {packages.map((c) => (
                <div style={{ float: "left", marginLeft: 20 }}>
                  <Card
                    style={{
                      marginLeft: 0,
                      maxWidth: 250,
                      minHeight: 460,
                      paddingLeft: 10,
                      align: "top",
                    }}
                  >
                    <Card.Body>
                      <InternalNoteComponent
                        list={packages}
                        setList={setPackages}
                        oldInternalNote={c.internalNote}
                        selectedUserByNote={c._id}
                        clickable={false}
                        note={c.note || ""}
                      />
                      <Card.Title>{c.title}</Card.Title>
                      <NavLink
                        to={"/package/" + c._id}
                        className="text-dark midText"
                      >
                        <i
                          alt="View"
                          title="View"
                          className="fa fa-binoculars"
                        ></i>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;
                      <NavLink
                        to={"/package/edit/" + c._id}
                        className="text-dark midText"
                      >
                        <i alt="Edit" title="Edit" className="fa fa-pen"></i>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;
                      <a
                        href="javascript:void(0)"
                        className="text-dark midText"
                        onClick={(e) => deletePackage(e, c._id)}
                      >
                        <i
                          alt="Delete"
                          title="Delete"
                          className="fa fa-trash"
                        ></i>
                      </a>
                      <br />
                      {c.company ? (
                        <>
                          <NavLink
                            to={"/invoice/new?package=" + c._id}
                            className="text-dark midText"
                          >
                            <i
                              alt="Create Invoice"
                              title="Create Invoice"
                              className="fa fa-file"
                            >
                              {" "}
                              <span className="faText">Create Invoice</span>
                            </i>
                          </NavLink>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {c.draft ? (
                        ""
                      ) : c.terminated && c.packageReturned !== true ? (
                        <>
                          <button
                            className="linkBtn red text-dark small-link midText"
                            onClick={() => activateSubscription(c._id)}
                          >
                            <i className="fa fa-plug">
                              {" "}
                              <span className="faText">Activate</span>
                            </i>
                          </button>
                          <br />
                        </>
                      ) : !c.terminated ? (
                        <>
                          <button
                            className="linkBtn red text-dark small-link midText"
                            onClick={() => handleReasonModalShow(c._id)}
                          >
                            <i className="fa fa-power-off">
                              {" "}
                              <span className="faText">Terminate</span>
                            </i>
                          </button>
                          <br />
                        </>
                      ) : null}
                      {c.terminated && c.packageReturned !== true ? (
                        <>
                          {" "}
                          <button
                            className="linkBtn text-dark small-link midText"
                            onClick={() => markPackageAsReturned(c._id)}
                          >
                            <i className="fa fa-box">
                              {" "}
                              <span className="faText">Return Package</span>
                            </i>
                          </button>
                          <br />
                          <button
                            className="linkBtn greenLink mx-0"
                            onClick={() =>
                              handleAddInternalNoteClick(c._id, c.internalNote)
                            }
                          >
                            <i className="fa fa-comment">
                              {" "}
                              <span className="faText">
                                Add an internal note
                              </span>
                            </i>
                          </button>
                          <br />
                        </>
                      ) : null}
                      {c.company ? (
                        <>
                          <span className="tinyCardLabel">Company:&nbsp;</span>
                          <span className="midText">
                            {c.company ? c.company.name : ""}
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      <span className="tinyCardLabel">Price:&nbsp;</span>
                      <span className="midText">
                        {c.price} {c.currency}
                      </span>
                      <br />
                      <span className="tinyCardLabel">Bikes:&nbsp;</span>
                      <span className="midText">
                        {c.bikes && c.bikes.length > 0 ? c.bikes.length : 0}
                      </span>
                      <br />
                      <span className="tinyCardLabel">Chargers:&nbsp;</span>
                      <span className="midText">
                        {c.chargers && c.chargers.length > 0
                          ? c.chargers.length
                          : 0}
                      </span>
                      <br />
                      <span className="tinyCardLabel">Batteries:&nbsp;</span>
                      <span className="midText">
                        {c.bats && c.bats.length > 0 ? c.bats.length : 0}
                      </span>
                      <br />
                      <span className="tinyCardLabel">Bottles:&nbsp;</span>
                      <span className="midText">{c.bottles}</span>
                      <br />
                      <span className="tinyCardLabel">Bags:&nbsp;</span>
                      <span className="midText">{c.bags}</span>
                      <br />
                      {c.helmetSize1 ? (
                        <>
                          <span className="tinyCardLabel">
                            {c.helmetId1 && c.helmetId1.number
                              ? c.helmetId1.number + "-" + c.helmetId1.label
                              : "Helmet Size 1"}
                            :&nbsp;
                          </span>
                          <span className="midText">{c.helmetSize1}</span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {c.helmetSize2 ? (
                        <>
                          <span className="tinyCardLabel">
                            {c.helmetId2 && c.helmetId2.number
                              ? c.helmetId2.number + "-" + c.helmetId2.label
                              : "Helmet Size 2"}
                            :&nbsp;
                          </span>
                          <span className="midText">{c.helmetSize2}</span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {c.nextRenewal != "" && c.nextRenewal != "None" ? (
                        <>
                          <span className="tinyCardLabel">
                            Next Renewal:&nbsp;
                          </span>
                          <span className="midText">
                            {DateFromatter.DateFormatterOne(c.nextRenewal)}
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      <span className="tinyCardLabel">Invoice:&nbsp;</span>
                      <span className="midText">
                        {c.invoice ? (
                          <>
                            <button
                              onClick={() =>
                                viewPDF(c.invoice._id, c.invoice.fileName)
                              }
                              className="linkBtn text-dark "
                            >
                              {c.invoice.number}
                            </button>
                          </>
                        ) : (
                          "None"
                        )}
                      </span>
                      <br />
                      {currentUser.type === "admin" ? (
                        <>
                          <span className="tinyCardLabel">Branch:&nbsp;</span>
                          <span className="midText">{c.branch.label}</span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      <span className="tinyCardLabel">Status:&nbsp;</span>
                      <span className="midText">
                        {c.disabled ? (
                          <span className="red">Disabled</span>
                        ) : (
                          <>
                            {c.packageReturned ? (
                              <span style={{ color: "red" }}>
                                Package Returned{" "}
                                {c.packageReturnedDate
                                  ? " at " +
                                    DateFromatter.DateFormatterOne(
                                      c.packageReturnedDate
                                    )
                                  : ""}
                              </span>
                            ) : c.terminated ? (
                              <span style={{ color: "red" }}>Terminated</span>
                            ) : c.draft ? (
                              <span style={{ color: "red" }}>Draft</span>
                            ) : !c.invoice ? (
                              <span style={{ color: "green" }}>No invoice</span>
                            ) : c.invoice.sent && !c.invoice.paid ? (
                              <span style={{ color: "green" }}>
                                Invoice Sent - not paid
                              </span>
                            ) : c.invoice.paid ? (
                              <span style={{ color: "green" }}>
                                Invoice Paid
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>No Invoice</span>
                            )}
                          </>
                        )}
                      </span>
                      {c.terminateReason && c.terminated ? (
                        <>
                          <br />
                          <span className="tinyCardLabel">Reason:&nbsp;</span>
                          <span className="midText">{c.terminateReason}</span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </Row>

            <div className="mt-3">
              {"Items per Page: "}
              <select
                onChange={(e) => handlePageSizeChange(e.target.value)}
                value={itemsPerPage}
              >
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingtotal={1}
                boundarytotal={1}
                variant="outlined"
                color="primary"
                shape="rounded"
                onChange={handlePageChange}
                size="medium"
              />
            </div>
          </div>
        ) : loading ? (
          <Loader />
        ) : resetMsg != "" ? (
          <div className="defaultMsg">{resetMsg}</div>
        ) : (
          <div className="noResultsFound">{labels.NO_RESULTS}</div>
        )}
      </div>
    </>
  );
};

export default Packages;
