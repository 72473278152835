import React, { useState, useEffect } from "react";
import authHeader from "../services/auth-header";
import CategoryService from "../services/cashLog.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import commonFunctions from "../common/Functions";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import { categoryUsage } from "../labels/options_en";
import { getBranches } from "../common/getFunctions";
import { Card, Row, Col } from "react-bootstrap";
import BranchePullDown from "../commonComponents/BranchePullDown";

const currentUser = JSON.parse(localStorage.getItem("user"));
const Categories = () => {
  const [error, setError] = useState("");
  const [categorys, setCategories] = useState([]);
  const [branchFilter, setBranchFilter] = useState();
  const [usageFilter, setUsageFilter] = useState();
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState("");
  const [keywordFilter, setKeywordFilter] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const pageSizes = [9, 18, 24, 30, 36, 60];

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      await listCategories("", "", "", page, itemsPerPage);
    }

    onReady();
  }, []);

  const listCategories = async (
    branchFilter,
    usageFilter,
    keywordFilter,
    page,
    itemsPerPage
  ) => {
    setLoading(true);
    CategoryService.getCategoriesList(
      branchFilter,
      usageFilter,
      keywordFilter,
      parseInt(page - 1) * itemsPerPage,
      itemsPerPage
    ).then(
      (response) => {
        let data = response.data.data;
        setCategories(data.list);
        setTotal(data ? data.total : 0);
        setCount(Math.ceil(data.total / itemsPerPage));
        setResetMsg("");
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setError(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };
  const handlePageChange = async (event, value) => {
    setPage(value);
    await listCategories(
      branchFilter,
      usageFilter,
      keywordFilter,
      value,
      itemsPerPage
    );
  };

  const handleKeywordChange = (val) => {
    setKeywordFilter(val);
    resetResults();
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await listCategories(
      branchFilter,
      usageFilter,
      keywordFilter,
      1,
      newItemsPerPage
    );
  };
  const resetSearch = async () => {
    setBranchFilter("");
    await listCategories("", "", "", page, itemsPerPage);
  };
  const triggerSearch = async () => {
    await setPage(1); // return to first page
    await listCategories(
      branchFilter,
      usageFilter,
      keywordFilter,
      1,
      itemsPerPage
    );
  };
  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    resetResults();
  };
  const handleUsageChange = async (u) => {
    setUsageFilter(u);
    resetResults();
  };
  const resetResults = () => {
    setTotal(0);
    setCount(0);
    setCategories([]);
    setResetMsg(labels.RESET_MSG);
  };

  const deleteCategory = async (event, id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this category? \n Make sure no subscriptions are created for this category!"
      )
    ) {
      CategoryService.deleteCategory(id).then(
        (response) => {
          alert("Category deleted!");
          let oldList = categorys;
          var data = oldList.filter(function (obj) {
            return obj._id !== id;
          });
          setCategories(data);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  return (
    <div className="container">
      {currentUser.type == "admin" || currentUser.type == "manager" ? (
        <div className="floatRight ">
          <NavLink to={"/category/new"} className="greenLink mx-0">
            Add Category
          </NavLink>
        </div>
      ) : (
        ""
      )}
      <h3> Categories List </h3>

      <Row style={{ display: "flex", flexDirection: "row" }}>
        {currentUser && currentUser.type == "admin" ? (
          <Col md={3}>
            <BranchePullDown
              addFormGroup="no"
              extraClassLabel="filterLabelInput maintenanceLabels"
              branch={branchFilter}
              labelInside={false}
              forceBlank={true}
              showAll={true}
              setBranch={handleBranchChange}
              branches={branches}
            />
          </Col>
        ) : null}

        <Col md={3}>
          <label
            className="filterLabelInput maintenanceLabels"
            htmlFor="usageFilter"
          >
            Usage
          </label>
          <select
            name="usageFilter"
            className="form-control filterInput2"
            value={usageFilter}
            onChange={(e) => handleUsageChange(e.target.value)}
          >
            {categoryUsage.map((m) => (
              <option value={m.key}>{m.label}</option>
            ))}
          </select>
        </Col>
        <Col md={3}>
          <label
            className="filterLabelInput maintenanceLabels"
            htmlFor="keywordFilter"
          >
            Keyword
          </label>
          <input
            type="text"
            className="form-control filterInput2"
            name="keywordFilter"
            value={keywordFilter}
            onChange={(e) => handleKeywordChange(e.target.value)}
          />
        </Col>
        <Col style={{ marginTop: 40 }} md={2}>
          <table border="0">
            <tr>
              <td>
                <button
                  onClick={(e) => triggerSearch(e)}
                  className="btn btn-primary btn-block search-button "
                >
                  Go
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => resetSearch(e)}
                  className="btn btn-primary btn-block reset-button "
                >
                  Reset
                </button>
              </td>
            </tr>
          </table>
        </Col>
      </Row>

      {error ? (
        <header className="jumbotron">
          {" "}
          <h3>{error}</h3>{" "}
        </header>
      ) : null}
      {!error && !loading && categorys && categorys.length != 0 ? (
        <div>
          <Row>
            <Col>
              <span style={{ float: "right", marginLeft: 10 }}>
                {total} results
              </span>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "first baseline",
            }}
          >
            {categorys.map((b) => (
              <div style={{ float: "left", marginLeft: 20 }}>
                <Card
                  style={{
                    marginLeft: 0,
                    maxWidth: 250,
                    minHeight: 100,
                    paddingLeft: 10,
                    align: "top",
                  }}
                >
                  <Card.Body>
                    <Card.Title>{b.label}</Card.Title>
                    <NavLink
                      to={"/category/edit/" + b._id}
                      className="text-dark midText"
                    >
                      <i alt="Edit" title="Edit" className="fa fa-pen"></i>
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;
                    <NavLink
                      to={"/category/clone/" + b._id}
                      className="text-dark midText"
                    >
                      <i alt="Clone" title="Clone" className="fa fa-clone"></i>
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;
                    <a
                      href="javascript:void(0)"
                      className="text-dark midText"
                      onClick={(e) => deleteCategory(e, b._id)}
                    >
                      <i
                        alt="Delete"
                        title="Delete"
                        className="fa fa-trash"
                      ></i>
                    </a>
                    <br />
                    <span className="tinyCardLabel">Key:&nbsp;</span>
                    <span className="midText">{b.key}</span>
                    <br />
                    <span className="tinyCardLabel">Usage:&nbsp;</span>
                    <span className="midText">{b.usage}</span>
                    <br />
                    {currentUser.type === "admin" ? (
                      <>
                        <span className="tinyCardLabel">Branch:&nbsp;</span>
                        <span className="midText">
                          {b.branch ? b.branch.label : "All"}
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Row>

          <div className="mt-3">
            {"Items per Page: "}
            <select
              onChange={(e) => handlePageSizeChange(e.target.value)}
              value={itemsPerPage}
            >
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>

            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingtotal={1}
              boundarytotal={1}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={handlePageChange}
              size="medium"
            />
          </div>
        </div>
      ) : loading ? (
        <Loader />
      ) : resetMsg != "" ? (
        <div className="defaultMsg">{resetMsg}</div>
      ) : (
        <div className="noResultsFound">{labels.NO_RESULTS}</div>
      )}
    </div>
  );
};

export default Categories;
