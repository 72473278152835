import React, { useState, useEffect, useRef, Fragment } from "react";
import { Prompt } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { NumericFormat } from "react-number-format";
import Switch from "react-switch";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import UserService from "../services/user.service";
import { getBranches, getCompanies } from "../common/getFunctions";
import AuthService from "../services/user.service";
import EventBus from "../common/EventBus";
import * as options from "../labels/options_en";
import { required, validEmail } from "../common/Validation";
import RequiredLabel from "../common/RequiredLabel";
import * as labels from "../labels/en";
import OptionalLabel from "../common/OptionalLabel";
import DateFormatter from "../common/DateFromatter";
import PullDown from "../commonComponents/PullDown";
import commonFunctions from "../common/Functions";

const UserForm = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [type, setType] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [branch, setBranch] = useState("");
  const [branches, setBranches] = useState([]);
  const [company, setCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [membership, setMembership] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [floor, setFloor] = useState("");
  const [street, setStreet] = useState("");
  const [town, setTown] = useState("");
  const [userBalance, setUserBalance] = useState(0);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneOnly, setPhoneOnly] = useState("");
  const [internalNote, setInternalNote] = useState("");
  const [source, setSource] = useState("");
  const [plan, setPlan] = useState("");
  const [iAmTyping, setIAmTyping] = useState(false);
  const userId = props.match.params.id;

  const getUserInfo = async () => {
    if (userId) {
      UserService.getUserById(userId).then(
        async (response) => {
          let u = response.data;
          setfirst_name(u.first_name);
          setlast_name(u.last_name);
          if (u.phone[0] === "0" && u.phone[1] === "0")
            setPhone("+" + u.phone.slice(2));
          else if (u.phone[0] !== "+") {
            let p = "+";
            if (
              u.phone[0] !== "9" &&
              u.phone[1] !== "6" &&
              u.phone[2] !== "1"
            ) {
              p = p + "961";
            }
            setPhone(p + u.phone);
          } else setPhone(u.phone);
          setEmail(u.email);
          setGender(u.gender);
          setCompany(u.company);
          setType(u.type);
          setBranch(u.branch);
          setBuildingName(u.buildingName);
          setFloor(u.floor);
          setStreet(u.street);
          setTown(u.town);
          setCompanies(await getCompanies(u.branch));
          setPhoneCode(u.phoneCode);
          setPhoneOnly(u.phoneOnly);
          setInternalNote(u.internalNote);
          setSource(u.source);
          setMembership(u.membership ? true : false);
          setPlan(u.plan ? u.plan : "Basic");
          setUserBalance(u.userBalance);
          if (
            u.birthday &&
            u.birthdayString != "NaNNaN" &&
            u.birthday.indexOf("-") > -1 &&
            u.birthday.indexOf("--") == -1
          ) {
            //console.log(u.birthday);
            let b = u.birthday.split("-");

            if (b && b.length > 1) {
              setYear(b[0]);
              setMonth(parseInt(b[1]) - 1);
              setDay(parseInt(b[2].split("T")[0]));
            } else {
              let d = new Date(u.birthday);
              setDay(d.getDate());
              setMonth(d.getMonth());
              setYear(d.getFullYear());
            }
          }
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          alert(_content);
          setMessage(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      getUserInfo();
    }

    onReady();
  }, []);

  const handleSaveUser = (e) => {
    e.preventDefault();
    setIAmTyping(false);
    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (!phoneOnly) {
      setMessage(labels.PHONE_REQUIRED);
      setSuccessful(false);
      document.getElementById("msg").scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      return false;
    }
    if (!company && type === "resller") {
      alert("Reseller must have a company!!");
      return false;
    }

    if (checkBtn.current.context._errors.length === 0) {
      const data = {
        _id: userId,
        email: email,
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        company: company,
        birthday:
          year && month && day
            ? year +
              "-" +
              DateFormatter.padTo2Digits(parseInt(month) + 1) +
              "-" +
              DateFormatter.padTo2Digits(day) +
              "T00:00:00.000Z"
            : "",
        type: type,
        gender: gender,
        branch: branch,
        password: password,
        buildingName: buildingName,
        floor: floor,
        street: street,
        town: town || "",
        phoneCode: phoneCode,
        phoneOnly: phoneOnly,
        internalNote: internalNote,
        source: source,
        plan: plan,
        membership: membership,
        userBalance: commonFunctions.removeComma(userBalance),
      };
      AuthService.postUser(data).then(
        (response) => {
          setMessage("User Saved.");
          setSuccessful(true);
          props.history.push("/users");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  const handlePhoneChange = (phone, countryData) => {
    setPhone(phone);
    setPhoneOnly(phone.split(countryData.dialCode)[1]);
    setPhoneCode(countryData.dialCode);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setInternalNote(internalNote + "\n");
    }
  };

  const triggerSetType = (t) => {
    setType(t);
    setIAmTyping(true);
  };

  const triggerBranchChanged = async (b) => {
    setBranch(b);
    setCompanies(await getCompanies(b));
    setIAmTyping(true);
  };

  const setMembershipSwitch = () => {
    setMembership(!membership);
  };
  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />

      <Form
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        ref={form}
      >
        {!successful && (
          <table className="styled-table" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th style={{ width: "100%" }}>User Form</th>
              </tr>
            </thead>
            <tbody>
              <Row
                className="white-background "
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: 0,
                  marginRight: 0,
                }}
              >
                {/* firstname, lastname, email, phone */}
                <Col md={3} className="align-baseline">
                  <RequiredLabel htmlFor="first_name" text="First Name" />
                  <Input
                    type="text"
                    className="form-control"
                    name="first_name"
                    value={first_name}
                    onChange={(e) => {
                      setfirst_name(e.target.value);
                      setIAmTyping(true);
                    }}
                    validations={[required]}
                  />
                </Col>
                <Col md={3}>
                  <RequiredLabel htmlFor="last_name" text="Last Name" />
                  <Input
                    type="text"
                    className="form-control"
                    name="last_name"
                    value={last_name}
                    onChange={(e) => {
                      setlast_name(e.target.value);
                      setIAmTyping(true);
                    }}
                    validations={[required]}
                  />
                </Col>
                <Col md={3}>
                  <RequiredLabel htmlFor="email" text="Email" />
                  <Input
                    type="text"
                    className="form-control"
                    name="email"
                    value={email}
                    autoComplete="new-password"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setIAmTyping(true);
                    }}
                    validations={[required, validEmail]}
                  />
                </Col>
                <Col md={3}>
                  <RequiredLabel htmlFor="phone" text="Phone" />
                  <PhoneInput
                    country={"lb"}
                    inputClass="phoneUser"
                    buttonStyle={{ borderRadius: 0, width: 50 }}
                    name="email"
                    placeholder=""
                    value={phone}
                    onChange={(phone, countryData) => {
                      handlePhoneChange(phone, countryData);
                      setIAmTyping(true);
                    }}
                    validations={[required]}
                  />
                </Col>

                {/* branch - type - company - source */}
                <Col md={3}>
                  <RequiredLabel htmlFor="branch" text="Branch" />
                  <Select
                    className="form-control"
                    value={branch}
                    onChange={(e) => {
                      triggerBranchChanged(e.target.value);
                    }}
                    validations={[required]}
                  >
                    <option value=""></option>
                    {branches &&
                      branches.map((m, index) => (
                        <option value={m._id} key={"branch_" + index}>
                          {m.label}
                        </option>
                      ))}
                  </Select>
                </Col>
                <Col md={3}>
                  <RequiredLabel htmlFor="source" text="Source" />
                  <Select
                    className="form-control"
                    value={source}
                    onChange={(e) => {
                      setSource(e.target.value);
                      setIAmTyping(true);
                    }}
                    validations={[required]}
                  >
                    <option value=""></option>
                    {options.source_register.map((item, index) => (
                      <option key={"soruce" + index} value={item.key}>
                        {item.value}
                      </option>
                    ))}
                  </Select>
                </Col>
                <Col md={3}>
                  <RequiredLabel htmlFor="type" text="Type" />
                  <Select
                    className="form-control"
                    value={type}
                    onChange={(e) => {
                      triggerSetType(e.target.value);
                    }}
                    validations={[required]}
                  >
                    <option value=""></option>
                    {options.user_types.map(
                      (item, index) =>
                        (currentUser.type === "admin" ||
                          item.key !== "admin") && (
                          <option key={"category" + index} value={item.key}>
                            {item.value}
                          </option>
                        )
                    )}
                  </Select>
                </Col>
                {type === "reseller" ? (
                  <Col md={3}>
                    <RequiredLabel htmlFor="company" text="Company" />
                    <PullDown
                      defaultValue={company}
                      labelInside={false}
                      pulldownFor={"company"}
                      forceBlank={true}
                      setValue={(value) => {
                        setCompany(value);
                        setIAmTyping(true);
                      }}
                      options={companies}
                    />
                  </Col>
                ) : type === "subscriber" || type === "registered" ? (
                  <>
                    <Col md={3}>
                      <RequiredLabel htmlFor="membership" text="Membership" />
                      <Switch
                        onChange={(e) => {
                          setMembershipSwitch(e);
                          setIAmTyping(true);
                        }}
                        checked={membership}
                      />
                    </Col>
                  </>
                ) : (
                  ""
                )}

                {/* passwords */}
                {!userId ? (
                  <>
                    <Col md={6}>
                      <RequiredLabel htmlFor="password" text="Password" />
                      <Input
                        autoComplete="new-password"
                        type="password"
                        className="form-control"
                        name="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setIAmTyping(true);
                        }}
                        validations={[required]}
                      />
                    </Col>
                    <Col md={6}>
                      <RequiredLabel
                        htmlFor="password"
                        text="Confirm Password"
                      />
                      <Input
                        autoComplete="off"
                        type="password"
                        className="form-control"
                        name="cPassword"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          setIAmTyping(true);
                        }}
                        validations={[required]}
                      />
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row
                className="white-background "
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                {/* optional fields */}
                <Col md={6}>
                  <OptionalLabel htmlFor="birthday" text="Birthday" />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Input
                      type="number"
                      placeholder="DD"
                      className="form-control"
                      style={{ width: "90%" }}
                      name="day"
                      value={day}
                      onChange={(e) => {
                        setDay(e.target.value);
                        setIAmTyping(true);
                      }}
                    />
                    <select
                      className="form-control"
                      style={{ width: 200, marginRight: 20, marginLeft: 20 }}
                      name="month"
                      value={month}
                      onChange={(e) => {
                        setMonth(e.target.value);
                        setIAmTyping(true);
                      }}
                    >
                      <option value="">MM</option>
                      {months.map((m, i) => (
                        <option value={i} key={i}>
                          {m}
                        </option>
                      ))}
                    </select>
                    <Input
                      type="number"
                      placeholder="YYYY"
                      className="form-control"
                      style={{ width: "90%" }}
                      name="year"
                      value={year}
                      onChange={(e) => {
                        setYear(e.target.value);
                        setIAmTyping(true);
                      }}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <OptionalLabel htmlFor="gender" text="Gender" />
                  <Select
                    className="form-control"
                    value={gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                      setIAmTyping(true);
                    }}
                  >
                    <option value=""></option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Select>
                </Col>
                <Col md={3}>
                  <RequiredLabel htmlFor="plan" text="Plan" />
                  <Select
                    className="form-control"
                    value={plan}
                    onChange={(e) => {
                      setPlan(e.target.value);
                      setIAmTyping(true);
                    }}
                    validations={[required]}
                  >
                    <option value=""></option>
                    {options.plan_register.map((item, index) => (
                      <option key={"plan" + index} value={item.key}>
                        {item.value}
                      </option>
                    ))}
                  </Select>
                </Col>

                <Col md={3}>
                  <OptionalLabel htmlFor="buildingName" text="Building Name" />
                  <Input
                    type="text"
                    className="form-control"
                    name="buildingName"
                    value={buildingName}
                    onChange={(e) => {
                      setBuildingName(e.target.value);
                      setIAmTyping(true);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <OptionalLabel htmlFor="floor" text="Floor" />
                  <Input
                    type="text"
                    className="form-control"
                    name="floor"
                    value={floor}
                    onChange={(e) => {
                      setFloor(e.target.value);
                      setIAmTyping(true);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <OptionalLabel htmlFor="street" text="Street" />
                  <Input
                    type="text"
                    className="form-control"
                    name="street"
                    value={street}
                    onChange={(e) => {
                      setStreet(e.target.value);
                      setIAmTyping(true);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <OptionalLabel htmlFor="town" text="Town" />
                  <Input
                    type="text"
                    className="form-control"
                    name="town"
                    value={town}
                    onChange={(e) => {
                      setTown(e.target.value);
                      setIAmTyping(true);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <div className="form-group">
                    <OptionalLabel htmlFor="userBalance" text="User Balance" />
                    <NumericFormat
                      type="text"
                      className="form-control"
                      name="userBalance"
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      decimalSeparator="."
                      value={userBalance}
                      onChange={(e) => setUserBalance(e.target.value)}
                    />
                  </div>
                </Col>

                <Col md={9}>
                  <OptionalLabel htmlFor="notes" text="Internal Notes" />
                  <textarea
                    className="form-control"
                    name="notes"
                    autoComplete="off"
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={(e) => {
                      setInternalNote(e.target.value);
                      setIAmTyping(true);
                    }}
                    cols="30"
                    rows="2"
                    value={internalNote}
                  />
                </Col>
              </Row>
              <Row
                className="white-background "
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Col md={12}>
                  <button
                    className="btn btn-primary btn-block"
                    onClick={handleSaveUser}
                  >
                    Save
                  </button>
                  <br />
                </Col>
              </Row>
              <Row></Row>
            </tbody>
          </table>
        )}
        <div id="msg">
          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
        </div>
        <CheckButton style={{ display: "none" }} ref={checkBtn} />
      </Form>
    </Fragment>
  );
};

export default UserForm;
