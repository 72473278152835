import React, { useState, useEffect } from "react";
import authHeader from "../services/auth-header";
import BranchService from "../services/branch.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import { getBranches } from "../common/getFunctions";
import { Card, Row, Col } from "react-bootstrap";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
const currentUser = JSON.parse(localStorage.getItem("user"));
const Branches = () => {
  const [error, setError] = useState("");
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState("");
  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches("", true));
      setLoading(false);
    }

    onReady();
  }, []);

  const deleteBranch = async (event, id) => {
    if (window.confirm("Are you sure you want to delete this branch?")) {
      BranchService.deleteBranch(id).then(
        (response) => {
          alert("Branch deleted!");
          let oldList = branches;
          var data = oldList.filter(function (obj) {
            return obj._id !== id;
          });
          setBranches(data);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  return (
    <div className="container">
      {currentUser.type == "admin" || currentUser.type == "manager" ? (
        <div className="floatRight ">
          <NavLink to={"/branch/new"} className="greenLink mx-0">
            Add Branch
          </NavLink>
        </div>
      ) : (
        ""
      )}

      <h3> Branches List </h3>

      {error ? (
        <header className="jumbotron">
          {" "}
          <h3>{error}</h3>{" "}
        </header>
      ) : null}
      {!error && !loading && branches && branches.length != 0 ? (
        <div>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "first baseline",
            }}
          >
            {branches.map((b) => (
              <div style={{ float: "left", marginLeft: 20 }}>
                <Card
                  style={{
                    marginLeft: 0,
                    maxWidth: 250,
                    minHeight: 300,
                    paddingLeft: 10,
                    align: "top",
                  }}
                >
                  <Card.Body>
                    <Card.Title>{b.label}</Card.Title>
                    <NavLink to={"/branch/" + b._id} className="text-dark ">
                      <i
                        alt="View"
                        title="View"
                        className="fa fa-binoculars"
                      ></i>
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;
                    <NavLink
                      to={"/branch/edit/" + b._id}
                      className="text-dark "
                    >
                      <i alt="Edit" title="Edit" className="fa fa-pen"></i>
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;
                    <NavLink
                      to={"/branch/clone/" + b._id}
                      className="text-dark "
                    >
                      <i alt="Clone" title="Clone" className="fa fa-clone"></i>
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;
                    <a
                      href="javascript:void(0)"
                      className="text-dark"
                      onClick={(e) => deleteBranch(e, b._id)}
                    >
                      <i
                        alt="Delete"
                        title="Delete"
                        className="fa fa-trash"
                      ></i>
                    </a>
                    <br />
                    <span className="tinyCardLabel">Disabled:&nbsp;</span>
                    <span className="midText">
                      {b.disabled ? "Disabled" : "Active"}
                    </span>
                    <br />
                    <span className="tinyCardLabel">USD Rate:&nbsp;</span>
                    <span className="midText">
                      {b.currencyRate} {b.currency}
                    </span>
                    <br />
                    <span className="tinyCardLabel">Language:&nbsp;</span>
                    <span className="midText">{b.language}</span>
                    <br />
                    <span className="tinyCardLabel">Currency:&nbsp;</span>
                    <span className="midText">{b.currency}</span>
                    <br />
                    <span className="tinyCardLabel">Default Plan:&nbsp;</span>
                    <span className="midText">{b.defaultPlan || ""}</span>
                    <br />
                    <span className="tinyCardLabel">Company:&nbsp;</span>
                    <span className="midText">
                      {b.company ? b.company.name : "None"}
                    </span>
                    <br />
                    <span className="tinyCardLabel">Journey Form:&nbsp;</span>
                    <span className="midText">
                      {b.formId ? (
                        <NavLink
                          to={"/survey/" + b.formId}
                          target="_new"
                          className="text-dark midText"
                        >
                          {b.formLabel}
                        </NavLink>
                      ) : (
                        ""
                      )}
                    </span>
                    <br />
                    <span className="tinyCardLabel">Address:&nbsp;</span>
                    <span className="midText">
                      {b.buildingNumber ? (
                        <>{b.buildingNumber} building </>
                      ) : (
                        ""
                      )}{" "}
                      {b.floor ? (
                        b.buildingNumber ? (
                          <>, {b.floor}</>
                        ) : (
                          <>{b.floor} floor</>
                        )
                      ) : (
                        ""
                      )}{" "}
                      {b.buildingNumber || b.floor ? <br /> : ""}{" "}
                      {b.street ? (
                        <>
                          {b.street}, {b.town}
                        </>
                      ) : (
                        b.town
                      )}
                      <br />
                      {b.city}, {b.country}
                    </span>
                    <br />
                    <span className="tinyCardLabel">Phone:&nbsp;</span>
                    <span className="midText">{b.phone}</span>
                    <br />
                    <span className="tinyCardLabel">Time Zone:&nbsp;</span>
                    <span className="midText">{b.timezone}</span>
                    <br />
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Row>
        </div>
      ) : loading ? (
        <Loader />
      ) : resetMsg != "" ? (
        <div className="defaultMsg">{resetMsg}</div>
      ) : (
        <div className="noResultsFound">{labels.NO_RESULTS}</div>
      )}
    </div>
  );
};

export default Branches;
