import React from "react";
import { Row, Col } from "react-bootstrap";
import mapPic from "../images/map.png";
export const RESET_MSG = "Please select your filters, then click go.";
export const CONFIDENTIAL_INFO = "Those information are confidential!";
export const NO_RESULTS = "No results found!";
export const INVALID_PASSWORD = (
  <p>
    Your password must contain a minimum of 8 characters including:
    <br />- At least 1 uppercase.
    <br />- At least 1 digit.
  </p>
);
export const PASSWORD_CHANGED = "Password has been changed.";
export const iAgree1 = (
  <div>
    <p>
      I, the undersigned, by signing this Waiver, I assume all risks
      participating in the bicycle testing, training, promotion, or the use (the
      "Activity") of the bicycles or electric bicycles provided to me (the
      "Bicycle"). Without signing this form, I will not be able to participate
      in the Activity or the use of Bicycles. I acknowledge that the above
      Activity or use of Bicycles may pose some risk of personal injury and that
      I undertake and assume this risk for myself.
    </p>
    <p>
      I further waive and release the promoters of the Activity and
      manufacturers of Bicycle, the company Wave Bike SARL and the company
      Progoris B.V., and their employees, agents, and other personnel involved
      in any way, any insuring entity of the above, and their directors, board
      members, officers, employees, volunteers, agents, representatives, or
      assigns, as well as the Activity sponsors (the "Personnel"), from any and
      all liability, including, but not limited to, liability arising from
      negligence or fault of the entities or persons for any injury or
      disability which may occur as a result of my participation in the above
      Activity or use of Bicycle.
    </p>
    <p>
      I am assuming all risks that may arise from negligence or carelessness on
      the part of any of the persons or entities and generally, the Personnel
      being released, as well as from defective equipment, real property, or
      personal property that is owned, maintained, or controlled by the above
      persons.
    </p>
    <p>
      I CERTIFY THAT I AM PHYSICALLY FIT AND SUFFICIENTLY PREPARED FOR
      PARTICIPATION IN THE ACTIVITY OR USE OF BICYCLE AND THAT THERE ARE NO
      HEALTH-RELATED REASONS OR PROBLEMS WHICH WOULD PRECLUDE MY PARTICIPATION
      IN THE ACTIVITY OR USE OF BICYCLE. I HAVE NOT BEEN ADVISED OF ANY REASON
      WHICH WOULD LIMIT ME IN PARTICIPATING IN THE ACTIVITY.
    </p>
    <p>
      I UNDERSTAND AND AGREE THAT PARTICIPATION IN THE ACTIVITY OR USE OF
      BICYCLE CARRIES WITH IT THE RISK TO ME OF PERSONAL OR BODILY INJURY
      (INCLUDING DEATH) AND LOSS OR DAMAGE TO PROPERTY, AND I WILLINGLY AND
      KNOWINGLY ACCEPT THAT RISK. I ALSO UNDERSTAND AND AGREE THAT THE PERSONNEL
      (AND ANY MEMBER IN IT) DOES NOT AND WILL NOT GUARANTEE MY SAFETY DURING MY
      PARTICIPATION IN THE ACTIVITY OR USE OF BICYCLE. WITH THIS WAIVER, I,
      INDIVIDUALLY, AND ON BEHALF OF MY HEIRS, SUCCESSORS, ASSIGNS, AND PERSONAL
      REPRESENTATIVES, HEREBY RELEASE, RELIEVE, INDEMNIFY AND FOREVER DISCHARGE
      THE PERSONNEL AND ANY MEMBER OF IT, ITS EMPLOYEES, AGENTS, OFFICERS,
      TRUSTEES, AND REPRESENTATIVES (IN THEIR OFFICIAL AND INDIVIDUAL
      CAPACITIES) FROM ANY AND ALL LIABILITY WHATSOEVER FOR ANY PERSONAL OR
      BODILY INJURY (INCLUDING DEATH) AND LOSS OF OR DAMAGE TO PROPERTY THAT I
      MAY SUSTAIN, INCLUDING BUT NOT LIMITED TO ANY CLAIMS, DEMANDS, ACTIONS,
      CAUSES OF ACTION, JUDGMENTS, DAMAGES, EXPENSES, AND COSTS, INCLUDING
      ATTORNEY'S FEES, WHICH ARISE OUT OF, RESULT FROM, OCCUR DURING OR ARE
      CONNECTED IN ANY MANNER WITH MY PARTICIPATION IN THE ACTIVITY OR USE OF
      BICYCLE, ANY RELATED OR INDEPENDENT TRAVEL, IRRESPECTIVE OF WHETHER OR NOT
      THEY ARE SPONSORED, SUPERVISED OR CONTROLLED BY ANY OR THE PERSONNEL.
    </p>
    <p>
      I understand that I may be photographed while participating in the
      Activity or use of a bicycle. I agree to allow my photo, video, or film
      likeness to be used for any legitimate purpose by the Activity holders,
      sponsors, producers, and their assigns.
    </p>
    <p>
      I shall defend, hold harmless, and indemnify the parties from and against
      all losses, claims, damages, costs, or expenses (including reasonable
      legal fees, or similar costs) in connection with any action or claim
      brought or made (or threatened to be brought or made), for, or on account
      of any injuries or damages, received or sustained by myself arising during
      the course of the Activity or use of Bicycle.
    </p>
    <p>
      This Waiver constitutes the sole and only agreement between the parties
      concerning my release and indemnification as a condition for participating
      in this Activity or use of Bicycle. Any prior agreements, whether oral or
      in writing, shall be void and of no further effect. This Waiver may not be
      modified. I certify that I have read this document, and I fully understand
      its contents.
    </p>
    <p>
      {" "}
      I am aware that this is a release and indemnification of liability for
      myself, and I sign it of my own free will.
    </p>
  </div>
);

export const iAgree2_signed_intro = (
  <p>You have agreed in the following points:</p>
);
export const iAgree2_not_signed_intro = (
  <p>
    Kindly read the below points with care, to make sure this information is
    clear to you.
  </p>
);

export const iAgree2 = (
  <div>
    <ol>
      <li>
        Our service area is, at the moment, smaller Beirut (see picture). You
        can use the bike outside the service area, however, for any service to
        the bike it is the user's responsibility to be within the service area.
      </li>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={process.env.REACT_APP_SERVER_API + "/upload/map.png"}
      >
        <img
          className="scrollImg"
          src={mapPic}
          width={300}
          height={200}
          title="Service area Wave (13-04-2021)"
          alt="Service area Wave (13-04-2021)"
        />
      </a>

      <li>
        All maintenance is included in the subscription (even the smallest
        things). It is not allowed to fix anything on the bike yourself or by a
        third party. The responsibility for maintenance is exclusive for the
        Wave team (the owner of the bike).
      </li>
      <li>
        The subscription is automatically renewed. Renewal payments should be
        done 5 days before the actual renewal date, latest on the date of
        renewal. For late payment (after renewal date) you will be charged a fee
        of $5/day payment.
      </li>
      <li>
        If you choose to terminate your subscription, make sure to return the
        bike, the battery and the charger, latest on the final day of your
        subscription, otherwise we would have to charge you a penalty of *$5/day
        late*.
      </li>
      <li>
        The helmet is always yours to keep. However in case you terminate your
        subscription before the completion of 3 months, we will deduct the
        subsidized price of the helmet ($20) from your deposit to cover the cost
        of the helmet.
      </li>
      <li>
        You are not allowed to use the Wave bike off-road. The Wave bike is
        designed for use within the city environment.
      </li>
      <li>
        You are not allowed to put the Wave bike on a car's rack/carrier. These
        racks are typically not designed to carry e-bikes like the Wave bike and
        may cause damages that are not regular wear and tear damages. So if
        these occur, we have to charge you the repair costs for it. Therefore,
        we discourage the use of bicycle car racks.
      </li>
      <li>
        Regular maintenance of the bike will be carried out for free by the Wave
        team. Any damage or excessive wear and tear caused by incidents and
        misuse of the bicycle will be charged on the subscriber.
      </li>
      <li>
        You are obliged to lock your bike properly and well. This means to use
        both locks and use the chain to lock the bike to a non removable object,
        like a fence or street light. If the bike is not locked with both locks,
        you are not insured against theft. Best practice is to take out the
        battery or park the bike inside.
      </li>
      <li>The following items I received in good working order:</li>
    </ol>
    <Row style={{ flexDirection: "row", alignItems: "flex-start" }}>
      <Col md={5}>
        <b>and I will return:</b>
        <ul>
          <li>Wave bike</li>
          <li>Battery 48V-10.2Ah</li>
          <li>Charger 48V-2A (cord + adapter)</li>
          <li>Lock chain</li>
          <li>Two keys</li>
        </ul>
      </Col>
      <Col md={4}>
        <b>and I can keep:</b>
        <ul>
          <li>Helmet (see point 5)</li>
        </ul>
      </Col>
    </Row>
  </div>
);
export const PHONE_REQUIRED = "Phone is required.";
export const CARD_SHARE_INTERNAL =
  "Your id card is solely for internal use and will not be shared with any third parties.";
