import React from "react";
import FollowupService from "../services/followup.service";
import EventBus from "./EventBus";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const removeDuplicates = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

const CommaFormatted = (amount) => {
  if (
    amount &&
    amount != undefined &&
    amount != "" &&
    amount != null &&
    amount != "null"
  ) {
    return amount.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }
  return 0;
};

const removeComma = (amount) => {
  if (amount) {
    return amount.toString().replace(/,/g, "");
  } else {
    return amount;
  }
};

const makeMultipleRows = (text) => {
  let array = text.split("\n");
  return (
    <div>
      {array.map((sentence, index) => (
        <div key={index}>
          <span>{sentence}</span>
          <br />
        </div>
      ))}
    </div>
  );
};

const getBaloonColor = (text) => {
  if (text.length > 0) return "#FF6363";
  return "#41874d";
};

const validatePassword = (password) => {
  return (
    password.length > 0 && /^(?=.{8,}$)(?=.*[A-Z])(?=.*[0-9]).*$/.test(password)
  );
};

const getOptionsList = (list) => {
  let optionsList = [];
  list.map((l) => {
    if (l) {
      optionsList.push({ value: l._id, label: l.number, model: l.model });
    }
  });
  return optionsList;
};

const getBikeOptionsList = (list) => {
  let optionsList = [];
  console.info("list ===", list);
  list.map((l) => {
    if (l) {
      optionsList.push({
        value: l._id,
        label: l.number,
        kilometers: l.kilometers,
      });
    }
  });
  return optionsList;
};
const markAsFollowed = async (details, list, isDashboard) => {
  let userId = details.user ? details.user._id : details.userId || details._id;
  let companyId = details.company ? details.company._id : details.companyId;
  let l = [];
  await FollowupService.markAsFollowed(userId, companyId).then(
    (response) => {
      if (response.status === 200) {
        if (isDashboard) {
          l = list.map((c, i) => {
            if (c.details) {
              let id = c.details.user
                ? c.details.user._id
                : c.details.userId || c.details._id;
              if (id === userId) {
                c.followupDone = true;
                c.lastFollowup = new Date().toISOString();
              }
            }

            return c;
          });
        } else {
          l = list.map((c, i) => {
            if (c && c.length > 0) {
              for (let j = 0; j < c.length; j++) {
                if (c[j] && c[j].details) {
                  let id = c[j].details.user
                    ? c[j].details.user._id
                    : c[j].details.userId || c[j].details._id;

                  if (id === userId) {
                    c[j].followupDone = true;
                    c[j].lastFollowup = new Date().toISOString();
                  }
                }
              }
            }

            return c;
          });
        }
      } else {
        alert(response.data.message);
      }
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );
  return l;
};

const fillList = async (
  followupFor,
  title,
  styles,
  list,
  info,
  columns,
  isDashboard
) => {
  //console.log("list for " + title + ": ", list)
  if (list) {
    for (let i = 0; i < list.length; i++) {
      var day = "";
      var date = "";
      if (list[i]) {
        if (followupFor === "confirmedPickups") {
          date = list[i].pickupDate;
          day = parseInt(list[i].pickupDate.split("-")[2]);
        } else {
          date = list[i].followupDate;
          day = parseInt(list[i].followupDate.split("-")[2]);
        }

        let followupDoneTemp = false;
        if (list[i].lastFollowup && list[i].lastFollowup !== "") {
          var lastFollowupDate = new Date(list[i].lastFollowup);
          if (
            lastFollowupDate.toISOString().split("T")[0] === date.split("T")[0]
          ) {
            followupDoneTemp = true;
          }
        }

        if (day < 10) {
          day = "0" + day;
        }
        let data = {
          name: list[i].full_name
            ? list[i].full_name
            : list[i].user && list[i].user.full_name
            ? list[i].user.full_name
            : list[i].company
            ? list[i].company.name
            : "undefined",
          for: followupFor,
          title: title,
          styles: styles,
          details: list[i],
          branch: list[i].branch
            ? list[i].branch._id
            : list[i].barnch
            ? list[i].barnch._id
            : "undefined",
          userId: list[i].userId
            ? list[i].userId
            : list[i].id
            ? list[i].id
            : list[i].user
            ? list[i].user._id
            : null,
          lastFollowup: list[i].lastFollowup,
          followupDone: followupDoneTemp,
          companyId: list[i].company ? list[i].company._id : "",
        };

        if (isDashboard) columns.push(data);
        else {
          let index = info.monthDays.indexOf(day.toString());
          if (columns[index]) columns[index].push(data);
        }
      }
    }
  }

  return columns;
};

const countRepeatedWords = (str, amount) => {
  const stickyWords = [
    "the",
    "there",
    "by",
    "at",
    "and",
    "so",
    "if",
    "than",
    "but",
    "about",
    "in",
    "on",
    "the",
    "was",
    "for",
    "that",
    "said",
    "a",
    "or",
    "of",
    "to",
    "there",
    "will",
    "be",
    "what",
    "get",
    "go",
    "think",
    "just",
    "every",
    "are",
    "it",
    "were",
    "had",
    "i",
    "very",
    "",
    "he",
    "she",
    "they",
    "we",
    "not",
    "this",
    "much",
    "is",
    "an",
  ];
  str = str.toLowerCase();
  var splitUp = str.split(/\s/);
  const wordsArray = splitUp.filter(function (x) {
    return !stickyWords.includes(x);
  });
  var wordOccurrences = {};
  for (var i = 0; i < wordsArray.length; i++) {
    wordOccurrences["_" + wordsArray[i]] =
      (wordOccurrences["_" + wordsArray[i]] || 0) + 1;
  }

  var result = Object.keys(wordOccurrences).reduce(function (acc, currentKey) {
    /* you may want to include a binary search here */
    for (var i = 0; i < amount; i++) {
      if (currentKey.length < 4) continue; // avoid 2 characters text since we have the _ we add one
      if (!acc[i]) {
        acc[i] = {
          label: currentKey.slice(1, currentKey.length),
          value: wordOccurrences[currentKey],
        };
        break;
      } else if (acc[i].value < wordOccurrences[currentKey]) {
        acc.splice(i, 0, {
          label: currentKey.slice(1, currentKey.length),
          value: wordOccurrences[currentKey],
        });
        if (acc.length > amount) acc.pop();
        break;
      }
    }
    return acc;
  }, []);
  console.log("result is == ", result);
  return result;
};
export default {
  removeDuplicates,
  CommaFormatted,
  required,
  removeComma,
  makeMultipleRows,
  getBaloonColor,
  validatePassword,
  getOptionsList,
  getBikeOptionsList,
  markAsFollowed,
  fillList,
  countRepeatedWords,
};
