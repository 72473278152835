import React, { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import {
  getInvoicesList,
  getSubscriptionsList,
  getJourneysList,
} from "../../common/getFunctions";
import ProfileHeader from "./ProfileHeader";
import ProfileTabs from "./ProfileTabs";
import OverviewSection from "./OverviewSection";
import InvoicesSection from "./InvoicesSection";
import SubscriptionsSection from "./SubscriptionsSection";
import JourneysSection from "./JourneysSection";
import MaintenancesSection from "./MaintenancesSection";
import LogsSection from "./LogsSection";
import CardUploadSection from "./CardUploadSection";
import "./index.css";

const User = (props) => {
  const [userData, setUserData] = useState({});
  const [maintenances, setMaintenances] = useState([]);
  const [cumulativeInvoiceAmount, setCumulativeInvoiceAmount] = useState({});
  const [invoiceList, setInvoiceList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [journeysList, setJourneysList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState("overview");

  const userId = props.match.params.id;
  const currentUser = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await UserService.getUserDetailsById(userId);
        const user = userResponse.data.data.user;
        setUserData(formatUserData(user));
        setCumulativeInvoiceAmount(
          userResponse.data.data.cumulativeInvoiceAmount
        );
        setMaintenances(userResponse.data.data.maintenances);
        setInvoiceList(
          await getInvoicesList(userId, true, "", "", true, "", true)
        );
        const subscriptions = await getSubscriptionsList(
          userId,
          "",
          "",
          "",
          "",
          "",
          "",
          ""
        );
        setSubscriptionList(subscriptions.list);
        setJourneysList(await getJourneysList(0, 3, userId));
        setLoaded(true);
      } catch (error) {
        handleError(error);
      }
    };

    fetchData();
  }, [userId]);

  const formatUserData = (user) => ({
    first_name: user.first_name,
    last_name: user.last_name,
    phone: formatPhoneNumber(user.phone),
    email: user.email,
    gender: user.gender,
    type: user.type,
    branch: user.branch,
    company: user.company ? user.company.name : "",
    birthday: user.birthday,
    address: formatAddress(user),
    logs: user.logs,
    defaultCardId: user.cardId
      ? `${process.env.REACT_APP_SERVER_API}/upload/cardIds/${user.cardId}`
      : "",
    defaultCardId2: user.cardId2
      ? `${process.env.REACT_APP_SERVER_API}/upload/cardIds/${user.cardId2}`
      : "",
    picture: user.picture,
    iagree: user.iagree,
    internalNote: user.internalNote,
    userBalance: user.userBalance ? user.userBalance.toLocaleString() : "0",
  });

  const formatPhoneNumber = (phone) => {
    if (phone.startsWith("00")) {
      return "+" + phone.slice(2);
    }
    if (!phone.startsWith("+")) {
      const prefix = phone.startsWith("961") ? "+" : "+961";
      return prefix + phone;
    }
    return phone;
  };

  const formatAddress = (user) => {
    return !user.buildingName || !user.floor || !user.street || !user.town ? (
      <span className="missing">missing</span>
    ) : (
      `${user.buildingName} building, ${user.floor} floor, ${user.street}, ${user.town}`
    );
  };

  const handleError = (error) => {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    alert(message);
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access, e.g., redirect to login
      window.location.href = "/login";
    }
  };

  return (
    <div className="user-profile" id="mainUserProfile">
      <ProfileHeader
        userData={userData}
        userId={userId}
        currentUser={currentUser}
      />
      <ProfileTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        currentUser={currentUser}
        type={userData.type}
      />
      <div className="profile-content">
        <OverviewSection
          userData={userData}
          activeTab={activeTab}
          userId={userId}
          cumulativeInvoiceAmount={cumulativeInvoiceAmount}
        />
        <InvoicesSection
          userId={userId}
          invoiceList={invoiceList}
          activeTab={activeTab}
        />
        <SubscriptionsSection
          userId={userId}
          subscriptionList={subscriptionList}
          activeTab={activeTab}
        />
        <JourneysSection
          userId={userId}
          journeysList={journeysList}
          activeTab={activeTab}
        />
        <MaintenancesSection
          maintenances={maintenances}
          activeTab={activeTab}
        />
        <LogsSection logs={userData.logs} activeTab={activeTab} />
        <CardUploadSection
          userId={userId}
          setUserData={setUserData}
          userData={userData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </div>
  );
};

export default User;
