import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Pagination from "@material-ui/lab/Pagination";
import { Card, Row, Col, Table } from "react-bootstrap";

import ResellerService from "../services/reseller.service";
import EventBus from "../common/EventBus";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import PullDown from "../commonComponents/PullDown";
import { getBranches, getSuppliers } from "../common/getFunctions";
import commonFunctions from "../common/Functions";
import DateFormatter from "../common/DateFromatter";
import { viewPDF } from "../common/pdf";

const currentUser = JSON.parse(localStorage.getItem("user"));

const ResellerInvoices = (props) => {
  const [error, setError] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [branchFilter, setBranchFilter] = useState();
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState("");
  const [numberFilter, setNumberFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [startFilter, setStartFilter] = useState("");
  const [endFilter, setEndFilter] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [waveIncome, setWaveIncome] = useState(0);
  const [isSingleCompany, setIsSingleCompany] = useState(false);
  const [companyIncome, setCompanyIncome] = useState(0);
  const [count, setCount] = useState(0);
  const pageSizes = [9, 18, 24, 30, 36, 60];
  const [companyList, setCompanyList] = useState([]);
  const [company, setCompany] = useState(props.match.params.id || "");
  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      const startDate = new Date();
      const endDate = new Date();
      startDate.setDate(1);
      setStartFilter(startDate);
      setEndFilter(endDate);
      setBranches(await getBranches());
      setBranchFilter(currentUser.branch);
      setCompanyList(await getSuppliers(currentUser.branch));
      await listResellerInvoices(
        currentUser.branch,
        "",
        company,
        startDate,
        endDate,
        "checkout",
        page,
        itemsPerPage
      );
    }
    setStatusFilter("checkout");
    onReady();
  }, []);

  const handleNumberChange = async (s) => {
    setNumberFilter(s);
    resetResults();
  };

  /**
   *
   * @param {*} branchFilter
   * @param {*} numberFilter
   * @param {*} companyFilter
   * @param {*} startFilter
   * @param {*} endFilter
   * @param {*} statusFilter
   * @param {*} page
   * @param {*} itemsPerPage
   */
  const listResellerInvoices = async (
    branchFilter,
    numberFilter,
    companyFilter,
    startFilter,
    endFilter,
    statusFilter,
    page,
    itemsPerPage
  ) => {
    setLoading(true);
    ResellerService.getResellerInvoices(
      branchFilter,
      numberFilter,
      companyFilter,
      startFilter,
      endFilter,
      statusFilter,
      parseInt(page - 1) * itemsPerPage,
      itemsPerPage,
      false
    ).then(
      (response) => {
        let data = response.data.data;
        setInvoices(data.list);
        setTotal(data ? data.total : 0);
        setTotalIncome(data ? data.totalIncome : 0);
        setCompanyIncome(data ? data.companyIncome : 0);
        setWaveIncome(data ? data.waveIncome : 0);
        setIsSingleCompany(data ? data.isSingleCompany : false);
        setCount(Math.ceil(data.total / itemsPerPage));
        setResetMsg("");
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setError(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
    await listResellerInvoices(
      branchFilter,
      numberFilter,
      company,
      startFilter,
      endFilter,
      statusFilter,
      value,
      itemsPerPage
    );
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await listResellerInvoices(
      branchFilter,
      numberFilter,
      company,
      startFilter,
      endFilter,
      statusFilter,
      1,
      newItemsPerPage
    );
  };
  const resetSearch = async () => {
    await handleBranchChange(currentUser.branch);
    setNumberFilter("");
    setCompany("");
    setStartFilter("");
    setEndFilter("");
    setStatusFilter("checkout");
    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(1);
    setStartFilter(startDate);
    setEndFilter(endDate);
    await listResellerInvoices(
      currentUser.branch,
      "",
      "",
      startDate,
      endDate,

      "checkout",
      1,
      itemsPerPage
    );
  };
  const triggerSearch = async () => {
    await setPage(1); // return to first page
    await listResellerInvoices(
      branchFilter,
      numberFilter,
      company,
      startFilter,
      endFilter,
      statusFilter,
      1,
      itemsPerPage
    );
  };
  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    setCompany("");
    setCompanyList(await getSuppliers(bid));
    resetResults();
  };
  const resetResults = () => {
    setTotal(0);
    setCount(0);
    setInvoices([]);
    setResetMsg(labels.RESET_MSG);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      triggerSearch(e);
    }
  };

  const sendInvoice = async (id, name, bikerId) => {
    if (
      window.confirm(
        "Are you sure you want to send this invoice to " + name + "?"
      )
    ) {
      ResellerService.sendInvoice({
        id: id,
        bikerId: bikerId,
      }).then(
        (res) => {
          alert("Invoice is sent!");
          setInvoices(
            invoices.map((i) => {
              if (i._id === id) {
                i.sent = 1;
              }
              return i;
            })
          );
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  const deleteInvoice = async (id) => {
    let inv = invoices.filter((invoice) => invoice._id === id);
    let mssg = "Are you sure you want to delete this Invoice?";

    if (window.confirm(mssg)) {
      ResellerService.deleteInvoice(id).then(
        () => {
          alert("Invoice deleted!");
          setInvoices(invoices.filter((invoice) => invoice._id !== id));
          setTotal(total - 1);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  return (
    <div className="resellerInvoicesContainer">
      {currentUser && currentUser.type === "reseller" ? (
        <>
          <br /> <br />
        </>
      ) : (
        ""
      )}
      <h3> Invoices</h3>
      {currentUser && currentUser.type !== "reseller" ? (
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "first baseline",
            paddingLeft: 10,
          }}
        >
          {currentUser && currentUser.type == "admin" ? (
            <Col md={1.5}>
              <label
                className="filterLabelInput maintenanceLabels"
                htmlFor="branch"
              >
                Branch
              </label>

              <select
                className="form-control filterInput"
                value={branchFilter}
                onChange={(e) => handleBranchChange(e.target.value)}
              >
                <option value="" key="0">
                  All
                </option>
                {branches.map((branch, index) => (
                  <option key={index} value={branch._id}>
                    {branch.label}
                  </option>
                ))}
              </select>
            </Col>
          ) : null}

          <Col md={1.5}>
            <label
              className="filterLabelInput maintenanceLabels"
              htmlFor="company"
            >
              Company
            </label>
            <PullDown
              defaultValue={company}
              labelInside={false}
              pulldownFor={"company"}
              extraClass="form-control filterInput"
              forceBlank={true}
              setValue={(value) => {
                setCompany(value);
                resetResults();
              }}
              options={companyList}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}

      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "first baseline",
          paddingLeft: 10,
        }}
      >
        <Col md={1.5}>
          <label
            htmlFor="startFilter"
            className="filterLabelInput maintenanceLabels"
          >
            Billing From
          </label>
          <DatePicker
            dateFormat="dd-MM-yyyy"
            className="form-control  filterInput"
            name="startFilter"
            selected={startFilter}
            onChange={(date) => {
              setStartFilter(date);
              resetResults();
            }}
          />
        </Col>
        <Col md={1.5}>
          <label
            htmlFor="endFilter"
            className="filterLabelInput maintenanceLabels"
          >
            Billing To
          </label>
          <DatePicker
            dateFormat="dd-MM-yyyy"
            className="form-control filterInput"
            name="endFilter"
            selected={endFilter}
            onChange={(date) => {
              resetResults();
              setEndFilter(date);
            }}
          />
        </Col>

        <Col md={1.5}>
          <label
            htmlFor="endFilter"
            className="filterLabelInput maintenanceLabels"
          >
            Status
          </label>
          <select
            className="form-control  filterInput"
            value={statusFilter}
            onChange={(e) => {
              resetResults();
              setStatusFilter(e.target.value);
            }}
          >
            <option value="">All</option>
            <option value="checkin">Checked In</option>
            <option value="checkout">Checked Out</option>
          </select>
        </Col>
        <Col md={1.5}>
          <label
            htmlFor="numberFilter"
            className="filterLabelInput maintenanceLabels"
          >
            Number
          </label>
          <input
            type="text"
            className="form-control filterInput"
            name="numberFilter"
            value={numberFilter}
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => handleNumberChange(e.target.value)}
          />
        </Col>

        <Col md={1.5}>
          <label
            htmlFor="numberFilter"
            className="filterLabelInput maintenanceLabels"
          >
            &nbsp;
          </label>
          <table border="0">
            <tr>
              <td>
                <button
                  onClick={(e) => triggerSearch(e)}
                  className="btn btn-primary btn-block search-button "
                >
                  Go
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => resetSearch(e)}
                  className="btn btn-primary btn-block reset-button "
                >
                  Reset
                </button>
              </td>
            </tr>
          </table>
        </Col>
      </Row>

      {error ? (
        <header className="jumbotron">
          {" "}
          <h3>{error}</h3>{" "}
        </header>
      ) : null}
      {!error && !loading && invoices && invoices.length != 0 ? (
        <div>
          <Row>
            <Col>
              <span style={{ float: "right", marginLeft: 10 }}>
                {total} results - {totalIncome} USD
                {isSingleCompany ? (
                  <div>
                    <span className="tinyCardLabel">
                      <b>Wave Profit:</b>&nbsp;
                    </span>
                    <span className="midText">{waveIncome} USD -</span>
                    <span className="tinyCardLabel">
                      <b>Company Profit:</b>&nbsp;
                    </span>
                    <span className="midText">{companyIncome} USD</span>
                  </div>
                ) : (
                  ""
                )}
              </span>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "first baseline",
            }}
          >
            {invoices &&
              invoices.length > 0 &&
              invoices.map((invoice, index) => (
                <div key={index} style={{ float: "left", marginLeft: 20 }}>
                  <Card
                    style={{
                      marginLeft: 0,
                      maxWidth: 250,
                      minHeight: 460,
                      paddingLeft: 10,
                      align: "top",
                    }}
                  >
                    <Card.Body>
                      <Card.Title>
                        {invoice.user ? (
                          <>
                            {invoice.user.first_name} {invoice.user.last_name}{" "}
                            <br />
                            {invoice.user.email}
                            <br />
                            {invoice.user.phone}
                          </>
                        ) : (
                          <>{invoice.fullName}</>
                        )}
                      </Card.Title>
                      {invoice.status === "checkout" && invoice.fileName ? (
                        <>
                          <a
                            href="javascript:void(0)"
                            onClick={() =>
                              viewPDF(
                                invoice._id,
                                invoice.fileName,
                                true,
                                !invoice.sent
                              )
                            }
                            className="text-dark midText"
                          >
                            <i
                              alt="PDF"
                              title="PDF"
                              className="fa fa-file-pdf"
                            ></i>
                          </a>
                          &nbsp;&nbsp;
                          <a
                            href="javascript:void(0)"
                            onClick={() =>
                              sendInvoice(
                                invoice._id,
                                invoice.fullName,
                                invoice.user ? invoice.user._id : ""
                              )
                            }
                            className="text-dark midText"
                          >
                            <i
                              title="Resend"
                              alt="Resend"
                              className="fa fa-share"
                            ></i>
                          </a>
                        </>
                      ) : (
                        ""
                      )}

                      {currentUser &&
                      (currentUser.type === "admin" ||
                        currentUser.type === "manager") ? (
                        <>
                          &nbsp;&nbsp;
                          <a
                            href="javascript:void(0)"
                            className="red text-dark midText"
                            onClick={() => deleteInvoice(invoice._id)}
                          >
                            Delete
                          </a>
                        </>
                      ) : null}
                      <br />
                      {invoice.number ? (
                        <>
                          <span className="tinyCardLabel">Number:&nbsp;</span>
                          <span className="midText">{invoice.number}</span>{" "}
                          <br />
                        </>
                      ) : (
                        ""
                      )}

                      <span className="tinyCardLabel">Status:&nbsp;</span>
                      <span className="midText">{invoice.status}</span>
                      <br />

                      {invoice.distanceKM ? (
                        <>
                          <span className="tinyCardLabel">Distance:&nbsp;</span>
                          <span className="midText">
                            {Math.abs(invoice.distanceKM)} KM
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {invoice.timeHR ? (
                        <>
                          <span className="tinyCardLabel">
                            Total Hours:&nbsp;
                          </span>
                          <span className="midText">
                            {Math.abs(invoice.timeHR)}
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {invoice.status == "checkout" ? (
                        <>
                          <span className="tinyCardLabel">
                            Billing Date:&nbsp;
                          </span>
                          <span className="midText">
                            {DateFormatter.DateFormatterOne(
                              invoice.billingDate
                                ? invoice.billingDate
                                : invoice.end
                            )}
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}

                      <span className="tinyCardLabel">Start-End:&nbsp;</span>
                      <span className="midText">
                        {DateFormatter.DateFormatterOne(invoice.start)} -{" "}
                        {invoice.end && invoice.status == "checkout" ? (
                          DateFormatter.DateFormatterOne(invoice.end)
                        ) : (
                          <span className="red">Not Ended</span>
                        )}
                      </span>
                      <br />

                      <span className="tinyCardLabel">
                        Price (Ex Vat):&nbsp;
                      </span>
                      <span className="midText">
                        {" "}
                        {commonFunctions.CommaFormatted(invoice.amount * 1) +
                          " " +
                          "USD"}
                      </span>
                      <br />
                      {invoice.discount && invoice.discount != 0 ? (
                        <>
                          {" "}
                          <span className="tinyCardLabel">
                            <b>Discount:</b>&nbsp;
                          </span>
                          <span className="midText red">
                            {commonFunctions.CommaFormatted(
                              invoice.discount * 1
                            ) +
                              " " +
                              "USD"}
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      <span className="tinyCardLabel">
                        <b>VAT Price:</b>&nbsp;
                      </span>
                      <span className="midText">
                        {commonFunctions.CommaFormatted(invoice.vatAmount * 1) +
                          " " +
                          "USD"}
                      </span>
                      <br />
                      <span className="tinyCardLabel">
                        <b>Price (Inc VAT):</b>&nbsp;
                      </span>
                      <span className="midText">
                        {commonFunctions.CommaFormatted(
                          invoice.total_amount * 1
                        ) +
                          " " +
                          "USD"}
                      </span>
                      <br />
                      {invoice.bikeNumber ? (
                        <>
                          {" "}
                          <span className="tinyCardLabel">Bike:&nbsp;</span>
                          <span className="midText">{invoice.bikeNumber}</span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {invoice.batteryNumber ? (
                        <>
                          {" "}
                          <span className="tinyCardLabel">Battery:&nbsp;</span>
                          <span className="midText">
                            {invoice.batteryNumber}
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {invoice.chargerNumber ? (
                        <>
                          {" "}
                          <span className="tinyCardLabel">Charger:&nbsp;</span>
                          <span className="midText">
                            {invoice.chargerNumber}
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      <span className="tinyCardLabel">Helmet:&nbsp;</span>
                      <span className="midText">
                        {invoice.helmet ? (
                          <span className="green">Yes</span>
                        ) : (
                          <span className="red">No</span>
                        )}
                      </span>
                      <br />
                      {invoice.company && currentUser.type != "reseller" ? (
                        <>
                          <span className="tinyCardLabel">
                            Company Name:&nbsp;
                          </span>
                          <span className="midText">
                            {invoice.company.name}
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </Row>

          <div className="mt-3">
            {"Items per Page: "}
            <select
              onChange={(e) => handlePageSizeChange(e.target.value)}
              value={itemsPerPage}
            >
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>

            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingtotal={1}
              boundarytotal={1}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={handlePageChange}
              size="medium"
            />
          </div>
        </div>
      ) : loading ? (
        <Loader />
      ) : resetMsg != "" ? (
        <div className="defaultMsg">{resetMsg}</div>
      ) : (
        <div className="noResultsFound">{labels.NO_RESULTS}</div>
      )}
    </div>
  );
};

export default ResellerInvoices;
