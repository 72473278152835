import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import DateFormatter from "../common/DateFromatter.js";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import { getBranches } from "../common/getFunctions";
import { Row, Col, Card, Modal } from "react-bootstrap";
import bikerImg from "../images/biker.jpg";
import WaitingService from "../services/waiting.service";
import InternalNoteComponent from "../commonComponents/InternalNoteComponent";

const OnBoarding = () => {
  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [branches, setBranches] = useState([]);
  const [branchFilter, setBranchFilter] = useState();
  const [typeFilter, setTypeFilter] = useState("");
  const [keywordFilter, setKeywordFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [approvedFilter, setApprovedFilter] = useState(0);

  const [selectedUserById, setSelectedUserById] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [note, setNote] = useState("");
  const [action, setAction] = useState("");
  const [rejectCardIdCheck, setRejectCardIdCheck] = useState(false);
  const [textareaLabel, setTextareaLabel] = useState("");
  const [modalButtonText, setModalButtonText] = useState("");
  const [name, setName] = useState("");

  const pageSizes = [9, 18, 24, 30, 36, 60];
  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      await getUsers("", "registered", "", page, itemsPerPage, "");
    }

    onReady();
  }, []);

  const resetSearch = async () => {
    setBranchFilter("");
    setTypeFilter("");
    setKeywordFilter("");
    await getUsers("", "", "", page, itemsPerPage, "");
  };

  const getUsers = async (
    branchFilter,
    typeFilter,
    keywordFilter,
    page,
    itemsPerPage,
    approvedFilter
  ) => {
    setLoading(true);
    UserService.getUsersList(
      branchFilter,
      "registered",
      keywordFilter,
      (page - 1) * itemsPerPage,
      itemsPerPage,
      false,
      "no",
      approvedFilter,
      "no"
    ).then(
      (response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.list
        ) {
          let data = response.data.data;
          setUsers(data.list);
          setTotal(data ? data.total : 0);
          setCount(Math.ceil(data.total / itemsPerPage));
          setResetMsg("");
          setLoading(false);
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);
        setLoading(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const triggerSearch = async () => {
    await setPage(1); // return to first page
    await getUsers(
      branchFilter,
      typeFilter,
      keywordFilter,
      1,
      itemsPerPage,
      approvedFilter
    );
  };

  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    resetResults();
  };

  const handleApprovedChange = async (val) => {
    setApprovedFilter(val);
    resetResults();
  };
  const handleKeywordChange = async (val, triggerSearch) => {
    setKeywordFilter(val);
    resetResults();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      triggerSearch(e);
    }
  };

  const resetResults = () => {
    setTotal(0);
    setCount(0);
    setUsers([]);
    setResetMsg(labels.RESET_MSG);
  };
  const handlePageChange = async (event, value) => {
    setPage(value);
    await getUsers(
      branchFilter,
      typeFilter,
      keywordFilter,
      value,
      itemsPerPage,
      approvedFilter
    );
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await getUsers(
      branchFilter,
      typeFilter,
      keywordFilter,
      1,
      newItemsPerPage,
      approvedFilter
    );
  };

  const handleAddToWaitingListClick = (id, name) => {
    setSelectedUserById(id);
    setName(name);
    setModalTitle("Waiting Note for " + name);
    setAction("waitingList");
    setTextareaLabel(
      <span>
        Please set any comment about adding this client to waiting list.
        <br />
        <span className="smallText red">
          The client will be marked that he accepted our terms and conditions if
          not
        </span>
      </span>
    );
    setModalButtonText("Add to List");
    setShowModal(true);
  };
  const handleRejectUserClick = (id, name) => {
    setSelectedUserById(id);
    setName(name);
    setModalTitle("Reject " + name);
    setAction("rejectUser");
    setTextareaLabel(
      <span>
        Reason -{" "}
        <span className="smallText">The biker will be notified via email.</span>
      </span>
    );
    setModalButtonText("Reject");
    setShowModal(true);
  };

  const handleSaveChangesClick = () => {
    if (action === "waitingList") sendWaitingNoteHandler();
    else if (action === "rejectUser")
      sendRejectApproveHandler(selectedUserById, 2, name);
  };

  const sendWaitingNoteHandler = () => {
    if (
      window.confirm(
        name +
          " will be added to the waiting list, and he/she will be marked that he/she accepted our terms and conditions.\n\n Continue?"
      )
    ) {
      var data = {
        bikerId: selectedUserById,
        waitingNote: note,
      };

      WaitingService.addToWaitingListHelpdesk(data).then(
        (response) => {
          alert("User has been added to the waiting list.");
          setUsers(
            users.map((u, index) => {
              if (u._id === selectedUserById) {
                u.waitingList = true;
              }
              return u;
            })
          );
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(resMessage);
        }
      );
      handleModalClose();
    }
  };

  const sendRejectApproveHandler = (id, approved, name) => {
    const s = approved === 1 ? "approved" : "rejected";
    const s1 = approved === 1 ? "approve" : "reject";
    if (window.confirm("You are going to " + s1 + " " + name + ". Continue?")) {
      var data = {
        userId: id,
        rejectReason: note,
        approved: approved,
        removeCardId: rejectCardIdCheck,
      };

      UserService.userApproveReject(data).then(
        (response) => {
          alert("User has been " + s + ".");
          setUsers(
            users.map((u, index) => {
              if (u._id === id) {
                u.approved = approved;
                u.rejectReason = note;
              }
              return u;
            })
          );
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(resMessage);
        }
      );
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setSelectedUserById("");
    setName("");
    setNote("");
    setAction("");
    setModalTitle("");
    setRejectCardIdCheck(false);
    setShowModal(false);
  };

  const checkBoxHandler = () => {
    if (document.getElementById("checkBox-cardId").checked)
      document.getElementById("checkBox-cardId-label").style.color =
        "rgb(52, 58, 64)";
    setRejectCardIdCheck(!rejectCardIdCheck);
  };

  return (
    <div className="container">
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>{textareaLabel}</label>
          <textarea
            style={{ width: "100%", borderColor: "#dee2e6" }}
            onChange={(e) => setNote(e.target.value)}
            cols="30"
            rows="10"
            defaultValue={note}
          />
          {action === "rejectUser" ? (
            <div style={{ display: "flex", width: "93%" }}>
              <input
                id="checkBox-cardId"
                onChange={checkBoxHandler}
                style={{ width: "auto", margin: "5px" }}
                type="checkbox"
              />
              <label
                id="checkBox-cardId-label"
                style={{
                  width: "auto",
                  margin: "0px",
                  padding: "0px",
                  color: "#343a40",
                }}
              >
                Delete Identity Card
              </label>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
        <button
          style={{ width: "auto", margin: "10px" }}
          variant="primary"
          onClick={handleSaveChangesClick}
        >
          {modalButtonText}
        </button>
        <button
          style={{ width: "auto", margin: "10px" }}
          variant="primary"
          onClick={handleModalClose}
        >
          Cancel
        </button>
      </Modal>

      {error ? (
        <header className="jumbotron">
          {" "}
          <h3>{error}</h3>{" "}
        </header>
      ) : null}

      <div>
        <h3> On Boarding </h3>
        <div className="filterContainer">
          {currentUser && currentUser.type === "admin" ? (
            <>
              <label className="filterLabelInput" htmlFor="branch">
                Branch
              </label>

              <select
                className="form-control filterInput"
                value={branchFilter}
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e) => handleBranchChange(e.target.value)}
              >
                <option value="" key="0">
                  All
                </option>
                {branches &&
                  branches.length >= 1 &&
                  branches.map((branch, index) => (
                    <option key={index} value={branch._id}>
                      {branch.label}
                    </option>
                  ))}
              </select>
            </>
          ) : null}

          <label className="filterLabelInput" htmlFor="type">
            Client
          </label>
          <input
            type="text"
            className="form-control filterInput"
            name="keywordFilter"
            value={keywordFilter}
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => handleKeywordChange(e.target.value)}
          />

          <label className="filterLabelInput" htmlFor="approved">
            Status
          </label>
          <select
            className="form-control filterInput"
            name="approved"
            value={approvedFilter}
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => handleApprovedChange(e.target.value)}
          >
            <option value="">All</option>
            <option value="1">Approved</option>
            <option value="2">Rejected</option>
            <option value="0">Not Set</option>
          </select>
          <table border="0">
            <tr>
              <td>
                <button
                  onClick={(e) => triggerSearch(e)}
                  className="btn btn-primary btn-block search-button "
                >
                  Go
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => resetSearch(e)}
                  className="btn btn-primary btn-block reset-button "
                >
                  Reset
                </button>
              </td>
            </tr>
          </table>
        </div>

        {!error && !loading && users.length !== 0 ? (
          <div>
            <Row>
              <Col>
                <span style={{ float: "right", marginLeft: 10 }}>
                  {total} results
                </span>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "first baseline",
              }}
            >
              {users && users.length !== 0
                ? users.map((u, index) => (
                    <div key={index} style={{ float: "left", marginLeft: 20 }}>
                      <Card
                        style={{
                          marginLeft: 0,
                          width: 300,
                          minHeight: 470,
                          paddingLeft: 10,
                          align: "top",
                        }}
                      >
                        <Card.Body>
                          <InternalNoteComponent
                            list={users}
                            setList={setUsers}
                            oldInternalNote={u.internalNote}
                            selectedUserByNote={u.id}
                            clickable
                          />
                          <Card.Title
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {u.picture ? (
                              <img
                                style={{
                                  width: 50,
                                  height: 50,
                                  marginRight: 10,
                                }}
                                src={
                                  process.env.REACT_APP_SERVER_API +
                                  "/upload/profiles/" +
                                  u.picture
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = bikerImg;
                                }}
                              />
                            ) : (
                              <img
                                style={{
                                  float: "right",
                                  width: 50,
                                  height: 50,
                                  marginRight: 10,
                                }}
                                src={bikerImg}
                                alt="logo"
                              />
                            )}
                            <>
                              {u.first_name} {u.last_name}
                            </>
                          </Card.Title>

                          {!u.waitingList &&
                          (currentUser.type === "admin" ||
                            currentUser.type === "manager") ? (
                            <>
                              <button
                                className="linkBtn greenLink mx-0"
                                onClick={() =>
                                  handleAddToWaitingListClick(
                                    u.id,
                                    u.first_name + " " + u.last_name
                                  )
                                }
                              >
                                Add to waiting list
                              </button>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          {!u.approved || u.approved === 2 ? (
                            <>
                              <button
                                className="linkBtn greenLink mx-0"
                                onClick={() =>
                                  sendRejectApproveHandler(
                                    u.id,
                                    1,
                                    u.first_name + " " + u.last_name
                                  )
                                }
                              >
                                Approve
                              </button>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          <button
                            className="linkBtn greenLink mx-0"
                            onClick={() =>
                              handleRejectUserClick(
                                u.id,
                                u.first_name + " " + u.last_name
                              )
                            }
                          >
                            Reject
                          </button>
                          <br />
                          {u.cardId ? (
                            <>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                  process.env.REACT_APP_SERVER_API +
                                  "/upload/cardIds/" +
                                  u.cardId
                                }
                                className="greenLink mx-0"
                              >
                                Identity Card
                              </a>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          {u.cardId2 ? (
                            <>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                  process.env.REACT_APP_SERVER_API +
                                  "/upload/cardIds/" +
                                  u.cardId2
                                }
                                className="greenLink mx-0"
                              >
                                Identity Card 2
                              </a>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          <span className="midText">{u.email}</span>
                          <br />
                          <span className="midText">
                            {u.phone[0] === "0" && u.phone[1] === "0" ? (
                              <span>+{u.phone.slice(2)}</span>
                            ) : u.phone[0] !== "+" ? (
                              <span>
                                <span>+</span>
                                {u.phone[0] !== "9" &&
                                u.phone[1] !== "6" &&
                                u.phone[2] !== "1" ? (
                                  <span>961</span>
                                ) : null}
                                <span>{u.phone}</span>
                              </span>
                            ) : (
                              <span>{u.phone}</span>
                            )}
                          </span>
                          <br />

                          <span className="midText">{u.gender}</span>
                          <span className="midText">
                            {u.birthday ? (
                              <span>
                                {" "}
                                - {DateFormatter.getAge(u.birthday)} years old
                              </span>
                            ) : null}
                          </span>
                          {" - "}
                          <span className="midText">{u.type}</span>
                          <br />
                          <span className="midText">
                            {u.verifiedphone ? (
                              <span style={{ color: "green" }}>
                                Phone verified
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                Phone not verified
                              </span>
                            )}
                          </span>
                          <br />
                          <span className="midText">
                            {u.verifiedemail ? (
                              <span style={{ color: "green" }}>
                                Email verified
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                Email not verified
                              </span>
                            )}
                          </span>
                          <br />
                          {u.type === "registered" ||
                          u.type === "subscriber" ? (
                            <>
                              <span className="midText">
                                {u.iagree ? (
                                  <span style={{ color: "green" }}>
                                    iAgree checked
                                  </span>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    iAgree not checked
                                  </span>
                                )}
                              </span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          {u.type === "registered" ||
                          u.type === "subscriber" ? (
                            <>
                              <span className="tinyCardLabel">
                                Source:&nbsp;
                              </span>
                              <span className="midText">
                                {u.source ? u.source : "Unknown"}
                              </span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          <span className="tinyCardLabel">Approved:&nbsp;</span>
                          <span className="midText">
                            {u.approved === 1
                              ? "Yes"
                              : u.approved === 2
                              ? "No"
                              : "Not Set"}
                          </span>
                          <br />
                          {u.approved === 2 && u.rejectReason ? (
                            <>
                              <span className="tinyCardLabel">
                                Reject Reason:&nbsp;
                              </span>
                              <span className="midText">{u.rejectReason}</span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          <span className="tinyCardLabel">
                            Created at:&nbsp;
                          </span>
                          <span className="midText">
                            {DateFormatter.DateFormatterOne(u.createdAt)}
                          </span>
                          <br />
                          {currentUser.type === "admin" ? (
                            <>
                              <span className="tinyCardLabel">
                                Branch:&nbsp;
                              </span>
                              <span className="midText">
                                {u.branch ? u.branch.label : ""}
                              </span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                        </Card.Body>
                      </Card>
                    </div>
                  ))
                : "No recod found"}
            </Row>

            <div className="mt-3">
              {"Items per Page: "}
              <select
                onChange={(e) => handlePageSizeChange(e.target.value)}
                value={itemsPerPage}
              >
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingtotal={1}
                boundarytotal={1}
                variant="outlined"
                color="primary"
                shape="rounded"
                onChange={handlePageChange}
                size="medium"
              />
            </div>
          </div>
        ) : loading ? (
          <Loader />
        ) : resetMsg !== "" ? (
          <div className="defaultMsg">{resetMsg}</div>
        ) : (
          <div className="noResultsFound">{labels.NO_RESULTS}</div>
        )}
      </div>
    </div>
  );
};

export default OnBoarding;
